import {
	PUBLIC_SENTRY_DSN,
	PUBLIC_SENTRY_ENV,
	PUBLIC_API_URL,
	PUBLIC_OLD_API_URL
} from '$env/static/public'
import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	environment: PUBLIC_SENTRY_ENV,
	enabled: window.location.hostname === 'localhost' ? false : true,
	tracesSampleRate: 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [
		replayIntegration({
			networkDetailAllowUrls: [
				`${PUBLIC_API_URL}/v1/admin/organizations`,
				`${PUBLIC_API_URL}/v1/admin/qr-codes`,
				`${PUBLIC_API_URL}/rscapi/v1/sites`,
				`${PUBLIC_API_URL}/rscapi/v1/sa/users`,
				`${PUBLIC_API_URL}/rscapi/v1/sa/distributors`,
				`${PUBLIC_API_URL}/rscapi/v1/assets`,
				`${PUBLIC_API_URL}/rscapi/v1/aws/things`,
				// For RSC users
				`${PUBLIC_OLD_API_URL}/v1/sites`,
				`${PUBLIC_OLD_API_URL}/v1/sa/users`,
				`${PUBLIC_OLD_API_URL}/v1/sa/distributors`,
				`${PUBLIC_OLD_API_URL}/v1/assets`,
				`${PUBLIC_OLD_API_URL}/v1/aws/things`
			]
		})
	],
	ignoreErrors: [
		"get' on proxy: property 'javaEnabled' is a read-only and non-configurable data property on the proxy target but the proxy did not return its actual value"
	]
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
