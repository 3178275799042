import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [];

export const dictionary = {
		"/(authenticated)/(admin)": [5,[2]],
		"/(authenticated)/(admin)/assets": [6,[2]],
		"/(authenticated)/(admin)/assets/teebox-groups/[group]": [8,[2]],
		"/(authenticated)/(admin)/assets/[asset]": [7,[2]],
		"/(authenticated)/(admin)/distributors": [9,[2]],
		"/(unauthenticated)/forgot-password": [27,[4]],
		"/(authenticated)/(admin)/gateways": [10,[2]],
		"/(unauthenticated)/login": [28,[4]],
		"/(authenticated)/(admin)/organizations": [11,[2]],
		"/(authenticated)/(admin)/organizations/[id]": [12,[2]],
		"/(unauthenticated)/privacy-policy": [29,[4]],
		"/(authenticated)/(admin)/qr-codes": [13,[2]],
		"/(unauthenticated)/reset-password": [30,[4]],
		"/(unauthenticated)/rsc/forgot-password": [31,[4]],
		"/(unauthenticated)/rsc/reset-password": [32,[4]],
		"/(authenticated)/(admin)/sites": [14,[2]],
		"/(authenticated)/(admin)/sites/[site]": [15,[2]],
		"/(authenticated)/(admin)/users": [16,[2]],
		"/(authenticated)/(site)/[site]": [17,[3]],
		"/(authenticated)/(site)/[site]/assets": [18,[3]],
		"/(authenticated)/(site)/[site]/assets/teebox-groups/[group]": [20,[3]],
		"/(authenticated)/(site)/[site]/assets/[asset]": [19,[3]],
		"/(authenticated)/(site)/[site]/customers": [21,[3]],
		"/(authenticated)/(site)/[site]/customers/[customer]": [22,[3]],
		"/(authenticated)/(site)/[site]/products": [23,[3]],
		"/(authenticated)/(site)/[site]/reports": [24,[3]],
		"/(authenticated)/(site)/[site]/site-settings": [25,[3]],
		"/(authenticated)/(site)/[site]/transactions": [26,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';